.App {
  text-align: center;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiBreadcrumbs-li:hover:not(.Mui-disabled) {
  cursor: pointer;
}


.stepcontainer {
  align-items: center;
  width: 100%;
  height: 100px;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 33%;
  float: left;
  font-size: 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #f87800;
}
.progressbar li:before {
  width: 50px;
  height: 50px;
  content: counter(step);
  counter-increment: step;
  line-height: 50px;
  border: 2px solid black;
  display: block;
  text-align: center;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  background-color: white;
  color: black;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: black;
  top: 25px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}

.stepcontent {
  margin-left: 10%;
  width: 80%;
  color: black;
  font-size: medium;
  text-align: left;
  text-transform: none;
  margin-top: 3%;
}
.btn-start {
  background-color: #f87800;
  color: white;
}

/* The sidenav */
.sidenav {
  width: 100%;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {visibility: hidden}
}

@media only screen and (max-width:369px) {
  .progressbar ul {
    float: left;
    width: 100% !important;
  }
  .progressbar li {
    width: 100% !important;
    position: unset !important;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width:490px) {
  .jumbotron img {
    max-height: 75px;
  }
  .Toastify__toast-container {
    width: 100vw !important;
  }
}

@media screen and (min-width: 768px) {
  .jumbotron h1, .jumbotron .h1 {
    font-size: 63px;
  }
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.wishlistImage {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .addToWishlistButtonMobile {
    display: none;
  }
  .wishlistItem {
    min-height: 115px;
  }
  .wishlistImage {
    text-align: left;
  }
}

footer {
  margin-top: auto;
  padding: 5px;
  text-align: center;
  height: 66px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Toastify__toast-theme--light {
  margin: 2px 5px 0 5px !important;
}


.overlay-custom-class-name {

}

.react-confirm-alert-body {
  width: 315px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.MuiDivider-root {
  border-color: rgba(211,211,211,0.3) !important;
}

.base__svg-container {
  width: auto;
}

.lineChartTest .base__container {
  margin: 10px 20px;
}


.spk-header .MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: static;
  background-color: #101010;
  color: #fff;
}

.spk-header .MuiButton-textPrimary {
  color: white;
}

.spk-header .MuiList-root {
  list-style: none;
  margin: 0px;
  padding: 8px 0px;
  position: relative;
  outline: 0px;
}

#menu-appbar li {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 36px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

#menu-appbar li .MuiTypography-root {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
}

/*#menu-appbar {*/
/*  top: 16px;*/
/*  left: 755px;*/
/*}*/

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  background-color: unset !important;
}

table img {
  max-height: 200px;
  width: auto;
}